import * as React from 'react'

import SidebarLayout from '../components/sidebarLayout'

const Build
    = ({ pageContext }) => {


        return (
            <SidebarLayout title="Build Gatsbyjs"
                pageContext={pageContext}
                main={(
                    <div>
                        <form action='https://api.netlify.com/build_hooks/6268f8ae2b735e7922351a5d' method="post">
                            <button name="foo" value="upvote">Build</button>
                        </form>
                    </div>
                )}
            />
        )
    }


export default Build




